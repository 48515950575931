@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
}

.home,
.about,
.contact,
.trophies {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
  background-color: #fff;
  color: #092145;
}

.about,
.trophies {
  flex-direction: column;
  font-size: 2rem;
}
.amit-img {
  height: 50%;
}
.trophy-img {
  height: 50%;
}

.page-icon {
  margin-left: 1rem;
  font-size: 100px;
}

#error-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

@media screen and (max-width: 960px) {
  .home,
  .about,
  .contact {
    font-size: 2.5rem;
  }

  .page-icon {
    margin-left: 1rem;
    font-size: 80px;
  }
}
